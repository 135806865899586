import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, of, firstValueFrom } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { IResetarSenha } from '../../interfaces/authentication/resetar-senha.interface';
import { IUserLoginPayload } from '../../interfaces/authentication/user-login-payload.interface';
import { IUser } from '../../interfaces/authentication/user.interface';
import { environment } from '../../../environments/environment';
import { IDomeOldApiResponse } from '../../interfaces/shared/api-response.interface';
import { IRole } from '../../interfaces/authentication/roles.interface';
import Swal from 'sweetalert2';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    public user: Observable<any>;
    public userSubject: BehaviorSubject<any>;
    private tokenOldLocalStorageKey = 'tokenDomeOld';
    private tokenCloudLocalStorageKey = 'token';

    constructor(
        private router: Router,
        private http: HttpClient
    ) {
        this.userSubject = new BehaviorSubject<any>(null);
        this.user = this.userSubject.asObservable();
    }

    public get userValue(): IUser {
        return this.userSubject.value;
    }

    public login(payload: IUserLoginPayload) {
        return this.http.post<IUser>(`${environment.baseUrlDome}/Authenticator/login`, payload, { withCredentials: true }).pipe(
            switchMap((user: IUser) => {
                if (user.authenticated) {
                    this.setStorageAndUserVariables(user);
                } else {
                    throw Error(user.message || 'Falha ao autenticar');
                }
                return this.updateModulesByUser(user);
            })
        );
    }

    public updateModulesByUser(user: IUser) {
        return this.http.get<IDomeOldApiResponse<IRole[]>>(`${environment.baseUrlDomeCloud}/modulos/consultamodulosacoesporusuario`).pipe(
            map((roles) => {
                return this.setStorageAndUserVariables(user, roles.registro.map((role: IRole) => role.acoes).flat());
            })
        );
    }

    public async checkLicense() {
        const data = await firstValueFrom(this.http.get(`${environment.baseUrlDomeCloud}/licenca/validar/${this.getActiveEmitterCnpj()}`));
        return this.handleLicenseActions(data);
    }

    public async unblockUser() {
        return await firstValueFrom(
            this.http.post(`${environment.baseUrlDomeCloud}/licenca/desbloquear/${this.getActiveEmitterCnpj()}`, null)
        );
    }

    public handleLicenseActions(data) {
        sessionStorage.setItem('licenca', JSON.stringify(data.status));

        if (data.status === 1) {
            this.userSubject.next({ ...this.userValue, licenca: data.status });
            Swal.fire({
                title: 'Aviso de pagamento pendente',
                icon: 'warning',
                html: `
                Seu pagamento está atrasado. Para evitar o <b>bloqueio do produto Dome</b> é necessário regularizar seus
				débitos.
				<br><br>
				As faturas podem ser geradas através deste <a href="https://www2.linx.com.br/intranet/"'1 rel="noopener noreferrer" target="_blank"><b>link</b></a> inserindo seu login e senha ou através da agente
				digital
				Laís no site da Linx.
				<br><br>
				Caso já tenha efetuado o pagamento, desconsidere esta mensagem.
                `,
                heightAuto: false,
            });
            return true;
        }

        if (data.status === 2) {
            this.userSubject.next({ ...this.userValue, licenca: data.status });
            this.router.navigate(['bloqueio']);
            return false;
        }

        this.userSubject.next({ ...this.userValue, licenca: 0 });
        return true;
    }

    public logout() {
        this.stopRefreshTokenTimer();
        localStorage.clear();
        sessionStorage.clear();
        this.userSubject.next(null as unknown as IUser);
        this.router.navigate(['/login']);
    }

    public resetPassword(user: string): Observable<IResetarSenha> {
        return this.http.post<IResetarSenha>(
            `${environment.baseUrlDome}/usuarios/esqueciminhasenha`,
            {
                usuario: user,
            },
            {
                headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
                responseType: 'json',
            }
        );
    }

    public refreshToken() {
        return this.login({
            GrantType: 'refresh_token',
            Password: '',
            RefreshToken: localStorage.getItem('refreshToken')!,
            UserName: localStorage.getItem('username')!,
        }).pipe(
            catchError(() => {
                return of(null);
            }),
            switchMap(async (user) => {
                if (user) {
                    await firstValueFrom(this.updateModulesByUser(user));
                    return await this.checkLicense();
                } else {
                    this.logout();
                    return of(null);
                }
            })
        );
    }

    public updateUser(user: IUser) {
        this.userSubject.next(user);
        localStorage.setItem('username', user.loginUsuario);
        localStorage.setItem('refreshToken', user.refreshToken);
    }

    public setStorageAndUserVariables(user: IUser, roles?: string[]): IUser {
        this.userSubject.next({
            ...user,
            dome: { ...this.decodeToken(user.accessToken) },
            domeCloud: { ...this.decodeToken(user.accessTokenDomeCloud) },
            roles: roles ?? (this.userValue?.roles || []),
        });
        localStorage.setItem(this.tokenOldLocalStorageKey, user.accessToken);
        localStorage.setItem(this.tokenCloudLocalStorageKey, user.accessTokenDomeCloud);
        localStorage.setItem('idUsuario', user.idUsuario);
        localStorage.setItem('username', user.loginUsuario);
        localStorage.setItem('refreshToken', user.refreshToken);
        this.startRefreshTokenTimer();
        return user;
    }

    public getTokenDomeOld() {
        return localStorage.getItem(this.tokenOldLocalStorageKey);
    }

    public getTokenDomeCloud() {
        return localStorage.getItem(this.tokenCloudLocalStorageKey);
    }

    public isLoggedIn() {
        return this.userValue && this.getTokenDomeCloud() && this.getTokenDomeOld;
    }

    public getActiveEmitterCnpj() {
        return this.userValue?.economicGroup?.emitters[0]?.cnpjCpf;
    }

    public checkDefaultSubsidiary(cnpj: string) {
        return this.userValue.acessos.find((acesso: { cnpj: string }) => acesso.cnpj === cnpj)?.padrao || false;
    }

    public setDefaultSubsidiary(cnpj: string) {
        this.userValue.acessos = this.userValue.acessos.map((acesso: { cnpj: string; padrao: boolean }) => {
            acesso.padrao = acesso.cnpj === cnpj;
            return acesso;
        });
        this.setStorageAndUserVariables(this.userValue);
    }

    private refreshTokenTimeout: ReturnType<typeof setTimeout> = setTimeout(() => {}, 0);

    private decodeToken(token: string) {
        return JSON.parse(atob(token.split('.')[1]));
    }

    private startRefreshTokenTimer() {
        const jwtToken = this.decodeToken(this.userValue.accessTokenDomeCloud);
        const expires = new Date(jwtToken.exp * 1000);
        const timeout = expires.getTime() - Date.now() - 60 * 1000;
        this.refreshTokenTimeout = setTimeout(() => this.refreshToken().subscribe(), timeout);
    }

    private stopRefreshTokenTimer() {
        clearTimeout(this.refreshTokenTimeout);
    }
}
